import * as React from "react"
import WorkItem from "../../../components/WorkItem/WorkItem"
import Footer from "../../../components/Footer/Footer"
import Header from "../../../components/Header"
import Navbar from "../../../components/Navbar/Navbar"
import { graphql, Link, StaticQuery } from "gatsby"
import { useTranslation } from "../../../i18n"
import { splitArrayPer } from "../../../util/splitArray"
import { any } from "prop-types"
import { Helmet } from "react-helmet"
import { useEffect } from "react"

const query = graphql`
	{
		allStrapiPortfolioPage {
			nodes {
				title
				titleJapanese
				strapi_id
				slug
				color
				location
				thumbnail
				orderCount
			}
		}
	}
`

const Portfolio = () => {
	const { t, i18n } = useTranslation()
	useEffect(() => {
		i18n.changeLanguage("jp")
	}, [])

	return (
		<>
			<StaticQuery
				query={query}
				render={(data) => (
					<div className="bg-tofuLightGrey">
						<Navbar />
						<Header
							title={t("title", { ns: "portfolio" })}
							description={t("portfolio-desc", { ns: "portfolio" })}
						/>
						<Helmet>
							<title>TOFU Media</title>
							<meta
								name="description"
								content={
									"TOFU Mediaの制作実績、ポートフォリオをこちらのページでご紹介しています。事例紹介としてご覧ください。ANA、NBA、旭化成などのお客様に対し、宣伝、CM、プロモーション映像、アニメーションなどの制作を行って参りました。"
								}
							/>
							<meta name="keywords" content={"制作実績 映像 ポートフォリオ"} />
						</Helmet>
						<div className="w-[100%] mt-20 lg:mt-auto bg-tofuLightGrey  h-auto  items-center flex-col flex">
							{splitArrayPer(
								data.allStrapiPortfolioPage.nodes.sort(
									(a: any, b: any) => a.orderCount - b.orderCount || 99
								),
								2
							).map((row, index) => (
								<Row key={index}>
									{row.map((portfolioItem: any, index2) => (
										<WorkItem
											key={index2}
											Title={
												i18n.language === "en"
													? portfolioItem.title
													: portfolioItem.titleJapanese
											}
											Color={`bg-[${portfolioItem.color}]`}
											Location={portfolioItem.location}
											Img={portfolioItem.thumbnail}
											LinkTo={`/portfolio/${portfolioItem.slug}`}
										/>
									))}

								</Row>

							))}
							<WorkItem
								key='13'
								Title={
									i18n.language === "en"
										? 'Sisterhood リモート撮影"'
										: 'Sisterhood リモート撮影"'
								}
								Color={`bg-[#07184a]`}
								Location="Japan, Tokyo"
								Img="https://res.cloudinary.com/tofu-media/image/upload/c_thumb,g_north,h_720,w_1280/v1656756009/sisterhoodie/1_x82ntb.jpg"
								LinkTo={'/jp/portfolio/sisterhoodie/'}
							/>
						</div>
						<Footer />
					</div>
				)}
			/>
		</>
	)
}

export default Portfolio

const Row: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
	<div className="w-[96%] flex flex-col lg:flex-row items-center lg:justify-between">
		{children}
	</div>
)
